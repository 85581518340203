<mat-card class="detail-card p-0 rounded">
  <div class="section-header">
    <i class="fas fa-hourglass-half"></i>
    {{ 'TICKET.TIMETRACKING' | translate }}
  </div>
  <mat-card-content class="p-1" fxLayout="column" fxLayoutGap="15px">
    <div class="progress w-100-p mat-elevation-z1">
      <div
        class="progress-bar cursor-pointer"
        [ngClass]="{'bg-cyan': timeTrackingData.timeTrackingProgress <= 75, 'background-red': timeTrackingData.timeTrackingProgress > 75}"
        role="progressbar"
        [style.width.%]="timeTrackingData.timeTrackingProgress"
        aria-valuenow="15"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <label> {{ 'TICKET.BOOKING_TIME' | translate }}</label>
        <span>{{ timeTrackingData.bookingTimeText }}</span>
      </div>

      <div fxLayout="column">
        <omt-form-element [label]="'TICKET.MAXIMUM_TIME' | translate">
          @if (!state.editMode) {
            <span>{{ timeTrackingData.maximumTimeText }}</span>
          } @else {
            <input
              type="text"
              class="form-control redesign"
              placeholder="HH:mm"
              [ngModel]="ticket.maximumTime | timeFormatFromMs"
              (change)="setTime($event.target['value'], 'maximumTime')"
            />
          }
        </omt-form-element>
      </div>
    </div>
  </mat-card-content>
</mat-card>
