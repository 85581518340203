<mat-card class="detail-card p-0 rounded">
  <div class="section-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <i class="fas fa-check-square"></i>
      To-Do
    </div>

    <omt-to-do-progress-bar class="mr-3" fxFlex="40" fxFlex.lg="35" fxFlex.gt-lg="15" [todos]="ticket.toDos"/>
  </div>

  <omt-ticket-todo
    [ticketId]="ticket.id"
    [(todos)]="ticket.toDos"
    (todosChange)="todosUpdated($event)"/>
</mat-card>
