<omt-dialog-host title="Ticketart anpassen" (closeClicked)="closeDialog()">
  <!-- #region Name-->
  <div dialog-content fxLayout="column" [fxLayoutGap]="layout.layoutGap.desktop">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span fxFlex="10"
      >Name
        <span class="text-red">*</span>
      </span>
      <div fxFlex="55" fxLayout="column">
        <input
          #name="ngModel"
          class="form-control redesign"
          [(ngModel)]="ticketType.name"
          [ngClass]="{
            'is-invalid': name.invalid && name.touched,
            '': !name.invalid && name.touched
          }"
          maxlength="100"
          placeholder="Name"
          type="text"
          required
        />
        @if (name.invalid && name.touched) {
          <span class="invalid-feedback">{{ 'MANAGEMENT.NAME_IS_REQUIRED' | translate }}</span>
        }
      </div>
    </div>
    <!--endregion Name-->

    <!-- #region Account -->
    <div fxLayout="row" fxLayoutAlign="start center">
      <span fxFlex="10">Icon</span>
      <div fxFlex="55" fxLayout="column">
        <omt-icon-picker [currentIcon]="ticketType.faIcon" (iconSelected)="ticketType.faIcon = $event" appendTo="body"></omt-icon-picker>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <span fxFlex="10">{{ 'MANAGEMENT.COLOR' | translate }}</span>
      <div fxFlex="55" fxLayout="column">
        <input
          class="redesign"
          [value]="ticketType.color"
          [cpPosition]="'bottom'"
          [cpPositionOffset]="'50%'"
          [cpPositionRelativeToArrow]="true"
          [(colorPicker)]="ticketType.color"
        />
      </div>
    </div>
  </div>
  <!--endregion Account-->
  <div dialog-footer class="modal-actions">
    <button mat-fab color="primary" type="button" (click)="save()">
      <i class="fas fa-check fa-2x"></i>
    </button>
  </div>
</omt-dialog-host>
