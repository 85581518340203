import {Injectable} from '@angular/core';
import {TicketCommentModel} from '@ticket/models';

@Injectable({
    providedIn: 'root'
})
export class TicketCommentService {

    constructor() {
    }

    isUserComment(comment: TicketCommentModel): boolean {
        return comment.user != null;
    }

    getCommentCreator(comment: TicketCommentModel): string {
        if (this.isUserComment(comment)) {
            return comment.user.personal != null ? `${comment.user.personal.lastName}, ${comment.user.personal.firstName}` : comment.user.username;
        } else {
            return comment.customerUser.email;
        }
    }
}
