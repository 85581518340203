import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@core/core.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {DialogHostModule} from '@libs/ui/src';
import {
    TicketTemplateNameDialogComponent
} from '@ticket/dropdowns/ticket-template/ticket-template-name-dialog/ticket-template-name-dialog.component';
import {TicketTemplateComponent} from '@ticket/dropdowns/ticket-template/ticket-template.component';


@NgModule({
    declarations: [TicketTemplateNameDialogComponent, TicketTemplateComponent],
    exports: [
        TicketTemplateComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        CoreModule,
        TranslateModule,
        MatCardModule,
        FlexModule,
        MatTooltipModule,
        MatButtonModule,
        DialogHostModule
    ]
})
export class TicketTemplateModule {
}
