import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TicketCommentModel} from '@ticket/models';
import {ApiService} from '@core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class TicketCommentHttpService {
    constructor(private apiService: ApiService) {
    }

    create(comment: TicketCommentModel): Observable<TicketCommentModel> {
        return this.apiService.post<TicketCommentModel>('api/comment/create', comment);
    }

    getByTicketId(issueId: number): Observable<TicketCommentModel[]> {
        const urlParams = new HttpParams().set('ticketId', issueId.toString());

        return this.apiService.get<TicketCommentModel[]>('api/comment/ticket', urlParams);
    }

    update(comment: TicketCommentModel): Observable<TicketCommentModel> {
        return this.apiService.put<TicketCommentModel>('api/comment/update', comment);
    }

    delete(commentId: number): Observable<string> {
        return this.apiService.delete<string>('api/comment/delete?id=' + commentId.toString());
    }
}
