import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {TicketStateModel} from '@ticket/models';
import {TicketStateEnum} from '@core/enums/ticket-state.enum';
import {AppTranslateService} from '@core/services/global/app-translate.service';

@Component({
    selector: 'omt-ticket-status-dropdown',
    templateUrl: './ticket-status-dropdown.component.html',
    styleUrls: ['./ticket-status-dropdown.component.scss']
})
export class TicketStatusDropdownComponent extends EntityDropdown {
    @Input() selectedStatus: TicketStateModel;
    @Output() selectedStatusChange = new EventEmitter<TicketStateModel>();

    states: TicketStateModel[] = this.appTranslateService.translateEnum(TicketStateEnum);

    constructor(private readonly appTranslateService: AppTranslateService) {
        super();
    }

    emit(): void {
        this.selectedStatusChange.emit(this.selectedStatus);
    }

    selectStatus(item: TicketStateModel): void {
        this.selectedStatus = item;
        this.emit();
    }
}
