import {Component} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'omt-ticket-template-name-dialog',
    templateUrl: './ticket-template-name-dialog.component.html',
    styleUrls: ['./ticket-template-name-dialog.component.scss']
})
export class TicketTemplateNameDialogComponent {
    name: string;

    constructor(public dialogRef: MatDialogRef<TicketTemplateNameDialogComponent>) {
    }

    save(): void {
        this.dialogRef.close(this.name);
    }

    close(): void {
        this.dialogRef.close();
    }
}
