import {Component, Input} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketBudgetInfoModel, TicketModel} from '@ticket/models';

@Component({
    selector: 'omt-ticket-budget',
    templateUrl: './ticket-budget.component.html',
    styleUrls: ['./ticket-budget.component.scss']
})
export class TicketBudgetComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
    @Input() budgetInfo: TicketBudgetInfoModel;
}
