import {Component, Input} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketModel, TicketToDoModel} from '@ticket/models';

@Component({
    selector: 'omt-ticket-todos',
    templateUrl: './ticket-todos.component.html',
    styleUrls: ['./ticket-todos.component.scss']
})
export class TicketTodosComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;

    todosUpdated(todos: TicketToDoModel[]): void {
        this.ticket.toDos = [...todos];
    }
}
