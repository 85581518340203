import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketAttachmentModel, TicketModel} from '@ticket/models';

@Component({
    selector: 'omt-ticket-attachments',
    templateUrl: './ticket-attachments.component.html',
    styleUrls: ['./ticket-attachments.component.scss']
})
export class TicketAttachmentsComponent {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
    @Output() attachmentAdded = new EventEmitter<TicketAttachmentModel>();
    @Output() attachmentDeleted = new EventEmitter<number>();
}
