<div class="page-layout simple">
  <mat-card fxLayout="column" class="booking-correction">
    <span class="headline"> {{ 'TICKET.OUTSTANDING_WORKING_HOURS' | translate }}</span>

    <div class="table-responsive">
      <table class="table-enlarged" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        <ng-container matColumnDef="ticketKey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TICKET.TICKET_KEY' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/ticket', element.ticket.ticketKey]"> {{ element.ticket.ticketKey }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="ticketName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TICKET.TICKET_NAME' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.ticket.summary }}
          </td>
        </ng-container>

        <ng-container matColumnDef="from">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'WAREHOUSE.FROM' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.start | germanDateTimeFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="to">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'WAREHOUSE.TO' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.end | germanDateTimeFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TICKET.TEXT' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.comment }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TICKET.ACTIONS' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button (click)="openDialog(element)" mat-icon-button class="mr-1" [matTooltip]="'GLOBAL.ACCEPT' | translate">
              <i class="fas fa-check text-green cursor-pointer fa-1-5x"></i>
            </button>
            <button (click)="openDeleteDialog(element)" mat-icon-button [matTooltip]="'GLOBAL.DELETE' | translate">
              <i class="fas fa-times text-red cursor-pointer fa-1-5x"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (dblclick)="openDialog(row)"></tr>
        <tr *matNoDataRow>
          <td>
            <div class="p-1">{{ 'TICKET.NO_PENDING_WORKLOGS' | translate }}</div>
          </td>
        </tr>
      </table>
      <mat-paginator (page)="setPageSize($event)" [pageSizeOptions]="[15, 25, 50, 100, 250]" [pageSize]="getPageSize()"></mat-paginator>
    </div>
  </mat-card>
</div>
