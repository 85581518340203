import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TicketSearchDialogComponent} from '@core/components/ticket-search-dialog/ticket-search-dialog.component';
import {DialogHostModule} from '@libs/ui/src/components/dialog-host/dialog-host.module';
import {TicketListModule} from '@ticket/ticket-list/ticket-list.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [TicketSearchDialogComponent],
    imports: [
        CommonModule,
        DialogHostModule,
        TicketListModule,
        MatButtonModule,
        TranslateModule
    ],
    exports: [TicketSearchDialogComponent]
})
export class TicketSearchDialogModule {
}
