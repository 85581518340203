<mat-card class="detail-card p-0 rounded">
  <div class="section-header">
    <i class="fas fa-calendar-week text-white"></i>
    {{ 'GLOBAL.DATE' | translate }}
  </div>
  <mat-card-content class="pl-2 pr-2 pb-1" fxLayout="column" fxLayoutGap="1rem">
    <!--Created Date-->
    @if (ticket.id) {
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="30" fxFlex.lg="40">
          <i class="far fa-calendar-plus"></i>
          {{ 'TICKET.CREATION_DATE' | translate }}</label>
        <div fxFlex="70" fxFlex.lg="60">
          {{ ticket.createdAt | germanDateFormat }}, {{ ticket.createdAt | timeFormat }}
          Uhr
        </div>
      </div>
    }
    <!--Updated Date-->
    @if (ticket.id) {
      <div fxLayout="row" fxLayoutAlign="start center">
        <label fxFlex="30" fxFlex.lg="40">
          <i class="fas fa-sync-alt"></i>
          {{ 'TICKET.UPDATE_DATE' | translate }}</label>
        <span fxFlex="70" fxFlex.lg="60">
          {{ ticket.updatedAt | germanDateFormat }}, {{ ticket.updatedAt | timeFormat }}
          Uhr
        </span>
      </div>
    }
    <!--DueDate-->
    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="30" fxFlex.lg="40">
        <i class="fas fa-hourglass-end"></i>
        {{ 'TICKET.DUE_DATE' | translate }}
      </label>
      @if (!state.editMode) {
        <div fxFlex="70" fxFlex.lg="60">
          <span>{{ ticket.dueDateStart | germanDateTimeFormat | empty }}</span>
          @if (ticket.dueDateEnd) {
            <span> - {{ ticket.dueDateEnd | germanDateTimeFormat }}</span>
          }
        </div>
      } @else {
        <div fxLayout="column" fxLayoutGap="1rem" fxFlex="70" fxFlex.lg="60">
          <omt-date-picker [(ngModel)]="ticket.dueDateStart" [labelText]="'TICKET.DUE_DATE' | translate" [withTime]="true"></omt-date-picker>
          @if (ticket.dueDateStart) {
            <omt-date-picker [(ngModel)]="ticket.dueDateEnd" [labelText]="'TICKET.DUE_DATE_END' | translate" [withTime]="true">
            </omt-date-picker>
          }
        </div>
      }

    </div>

    <!--ReminderDate-->
    <div fxLayout="row" fxLayoutAlign="start center" class="mb-1">
      <label fxFlex="30" fxFlex.lg="40">
        <i class="fas fa-bell"></i>
        {{ 'TICKET.REMINDER_DATE' | translate }}</label>
      @if (!state.editMode) {
        <div fxFlex="70" fxFlex.lg="60">{{ ticket.reminderDate | germanDateFormat | empty }}</div>
      } @else {
        <omt-date-picker fxFlex="70" fxFlex.lg="60" [(ngModel)]="ticket.reminderDate" [labelText]="'TICKET.REMINDER_DATE' | translate"></omt-date-picker>
      }

    </div>
  </mat-card-content>
</mat-card>
