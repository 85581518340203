import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {CustomerModel} from '@customer/models';

@Component({
    selector: 'omt-customer-link',
    templateUrl: './customer-link.component.html',
    styleUrls: ['./customer-link.component.scss']
})
export class CustomerLinkComponent {
    @Input() customer: CustomerModel;

    constructor(private readonly router: Router) {
    }

    navigateToDetail(): void {
        this.router.navigate([`/customer/${this.customer.id}`]).then();
    }

}
