import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {PriorityModel, TicketModel, TicketStateModel, TicketTypeModel} from '@ticket/models';
import {TicketStateEnum} from '@core/enums/ticket-state.enum';
import {AppTranslateService} from '@core/services/global/app-translate.service';
import {ProjectHttpService} from '@app/project-management/project-http.service';
import {UserSettingsModel} from '@core/models/user/user-settings.model';
import {SubSink} from 'subsink';
import {CustomerModel} from '@customer/models';
import {TicketService} from '@ticket/ticket.service';
import {TicketTemplateModel} from '@management/tickets/template/ticket.template.model';
import {TicketTemplateService} from '@management/tickets/template/ticket-template.service';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';
import {BoolOptions} from '@core/models/bool-options';
import {BoardHttpService} from '@boards/board-http.service';
import {BoardModel} from '@boards/models';
import {TranslateService} from '@ngx-translate/core';
import {TicketTimeTrackingData} from '@ticket/ticket-detail-v2/ticket-time-tracking-data';

@Component({
    selector: 'omt-ticket-general',
    templateUrl: './ticket-general.component.html',
    styleUrls: ['./ticket-general.component.scss']
})
export class TicketGeneralComponent implements OnInit, OnDestroy {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
    @Input() template: TicketTemplateModel;
    @Input() settings: UserSettingsModel;
    @Input() businessUnits: BusinessUnitModel[];
    @Input() linkedBoards: BoardModel[] = [];
    @Input() boardsToLink: number[] = [];
    @Input() timeTrackingData: TicketTimeTrackingData;
    @Output() boardsToLinkChange = new EventEmitter<number[]>();
    @Output() timeChange = new EventEmitter<{ timeStr: string; field: string }>();
    @Output() estimatedTimeChange = new EventEmitter<void>();

    $boards = this.boardService.readAllPermittedBoards();
    maxTitleChars = 150;
    maxDescriptionChars = 25_000;

    states: TicketStateModel[] = this.appTranslateService.translateEnum(TicketStateEnum);
    boolOptions: BoolOptions[] = [
        {name: this.translate.instant('GLOBAL.YES'), value: true},
        {name: this.translate.instant('GLOBAL.NO'), value: false}
    ];

    get defaultCustomer(): number | null {
        return this.settings.ticketDefaultCustomerId;
    }

    get shouldSetDefaultCustomer(): boolean {
        return this.settings.ticketSetDefaultCustomer;
    }

    get defaultProject(): number | null {
        return this.settings.ticketDefaultProjectId;
    }

    get shouldSetDefaultProject(): boolean {
        return this.settings.ticketSetDefaultProject;
    }

    private subs = new SubSink();

    constructor(private readonly appTranslateService: AppTranslateService,
                private readonly projectService: ProjectHttpService,
                private readonly ticketService: TicketService,
                private readonly boardService: BoardHttpService,
                private readonly templateService: TicketTemplateService,
                private readonly translate: TranslateService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.setDefaultProject();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View
    // -----------------------------------------------------------------------------------------------------

    setTime(timeStr: string, field: string): void {
        this.timeChange.emit({timeStr, field});
    }

    handleCustomerChange(event: CustomerModel): void {
        this.ticketService.handleCustomerChange(this.ticket, event);
    }

    onChangeTemplate(template: TicketTemplateModel): void {
        this.ticket = this.templateService.mapTemplateToTicket(template, this.ticket);
        this.boardsToLink = template.boardIds;
        this.boardsToLinkChange.emit(this.boardsToLink);

        if (template.assignee) {
            this.ticket.assignee = this.businessUnits.find((x) => x.id === template.assignee.id);
        }
    }

    changePriority(priority: PriorityModel | null): void {
        this.ticket.priorityId = priority?.id;
    }

    linkedBoardNames(): string {
        return this.linkedBoards?.map((board) => board.name).join(', ') ?? '';
    }

    private setDefaultProject(): void {
        if (this.ticket.id || !this.defaultProject || !this.shouldSetDefaultProject) {
            return;
        }

        this.subs.sink = this.projectService.getPermittedProjects().subscribe((data) => {
            this.ticket.projectId = this.defaultProject;
            this.ticket.project = data.find((project) => project.id === this.defaultProject);
        });
    }

    setDefaultCustomer(customers: CustomerModel[]): void {
        if (this.ticket.id || !this.defaultCustomer || !this.shouldSetDefaultCustomer) {
            return;
        }

        const customer = customers.find((x) => x.id === this.defaultCustomer);

        this.ticket.customerId = this.defaultCustomer;
        this.ticket.customer = customer;
    }

    setDefaultTicketType(data: TicketTypeModel[]): void {
        if (this.ticket.id) {
            return;
        }

        this.ticket.ticketType = data.find((x: TicketTypeModel) => x.name?.toLowerCase()?.includes('todo')) ?? data[0];
    }

    copyDescription(): void {
        this.ticket.externalDescription = this.ticket.description;
    }
}
