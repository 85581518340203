<div fxFlex="100">
  <mat-card fxLayout="column" fxLayoutGap="20px" class="p-0 rounded">
    <div class="section-header mb-0">
      <i class="fab fa-sistrix"></i>
      {{ 'TICKET.DETAIL' | translate }}
    </div>
    <div class="card-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      @if (ticket.id) {
        <omt-number-range-key [key]="ticket.ticketKey" displayType="compact" keyType="ticket"></omt-number-range-key>
      }
      <!--Summary-->
      @if (!state.editMode) {
        <h2 fxFlex="1 1 auto" class="m-0">{{ ticket.summary }}</h2>
      }

      @if (state.editMode) {
        <div fxFlex="1 1 auto" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutAlign.lt-lg="start" fxLayoutGap="1rem" fxLayoutGap.lt-lg="1rem">
          <div fxLayout="column" fxFlex="1 1 auto">
            <input
              id="ticket-title"
              class="form-control font-weight-bold redesign"
              style="font-size: 24px"
              [(ngModel)]="ticket.summary"
              [maxlength]="maxTitleChars"
              [placeholder]="'TICKET.PH_SUMMARY' | translate"/>
            <omt-max-character-hint [value]="ticket.summary" [maxChars]="maxTitleChars"></omt-max-character-hint>
          </div>
          @if (!state.templateMode && !ticket.id) {
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="40">
              <label class="mb-0">{{ 'TICKET.TEMPLATE' | translate }}</label>
              <omt-ticket-template-dropdown (ticketTemplate)="onChangeTemplate($event)"></omt-ticket-template-dropdown>
            </div>
          }
        </div>
      }
    </div>

    <div fxLayout="column" fxLayoutGap="2rem" class="pl-2 pr-2">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="start">
        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'TICKET.TICKET_TYPE' | translate" required [showRequiredSymbol]="state.editMode">
          @if (!state.editMode) {
            <omt-ticket-type [ticketType]="ticket.ticketType"></omt-ticket-type>
          } @else {
            <omt-ticket-type-dropdown [(ticketType)]="ticket.ticketType" (loaded)="setDefaultTicketType($event)"></omt-ticket-type-dropdown>
          }
        </omt-form-element>

        @if (!state.templateMode) {
          <omt-form-element fxFlex="50" fxFlex.xl="50" label="Status" required [showRequiredSymbol]="state.editMode">
            @if (!state.editMode) {
              <div
                class="status status-small cursor-pointer"
                [ngClass]="{
                        new: ticket.ticketState.id === 1,
                        new2: ticket.ticketState.id === 7,
                        iProgress: ticket.ticketState.id === 2,
                        done: ticket.ticketState.id === 4,
                        canceld: ticket.ticketState.id === 3,
                        unsuccessful: ticket.ticketState.id === 5,
                        archived: ticket.ticketState.id === 6
                      }">
                {{ ticket.ticketState.name }}
              </div>
            } @else {
              <omt-ticket-status-dropdown [(selectedStatus)]="ticket.ticketState"></omt-ticket-status-dropdown>
            }
          </omt-form-element>
        } @else {
          <omt-form-input fxFlex="50"
                          fxFlex.xl="50"
                          type="text"
                          [(value)]="template.name"
                          [editMode]="state.editMode"
                          [label]="'MANAGEMENT.NAME_OF_TEMPLATE' | translate"></omt-form-input>
        }
      </div>

      <div fxLayout="row" fxLayoutGap="2rem" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="start">
        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'TICKET.CUSTOMER' | translate" required [showRequiredSymbol]="state.editMode">
          @if (!state.editMode) {
            <omt-customer-link [customer]="ticket.customer"></omt-customer-link>
          } @else {
            <omt-customer-dropdown
              (customersLoaded)="setDefaultCustomer($event)"
              (selectedCustomerChange)="handleCustomerChange($event)"
              [(selectedCustomer)]="ticket.customer"
              [selectedProjectId]="ticket.project?.id"
              [clearable]="false"
            ></omt-customer-dropdown>
          }
        </omt-form-element>

        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'REPORTING.CUSTOMER_REFERENCE' | translate">
          @if (!state.editMode) {
            <div>
              {{ ticket.customerRef?.name | empty }}
            </div>
          } @else {
            <omt-customer-ref-dropdown
              [disabled]="!ticket.customer && !ticket.customerId"
              [(value)]="ticket.customerRef"
              [customerId]="ticket.customer?.id"
              (valueChange)="ticket.customerRefId = $event?.id"
            ></omt-customer-ref-dropdown>
          }
          <!--state.editMode-->
        </omt-form-element>
      </div>

      <div fxLayout="row" fxLayoutGap="2rem" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="start">
        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'CUSTOMER.CONTACT_PERSON' | translate">
          @if (!state.editMode) {
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              @if (ticket.customerRefPerson || ticket.customerRefPersonId) {
                <omt-avatar [avatarUrl]="ticket.customerRefPerson?.avatarUrl" size="24"></omt-avatar>
                <span>{{ ticket.customerRefPerson?.firstName }} {{ ticket.customerRefPerson?.lastName }}</span>
              } @else {
                {{ '' | empty }}
              }
            </div>
          } @else {
            <omt-customer-ref-person-dropdown [(value)]="ticket.customerRefPerson" [customerId]="ticket.customer?.id ?? ticket.customerId" [disabled]="!ticket.customerId &&
            !ticket.customer" [clearable]="true"></omt-customer-ref-person-dropdown>
          }
        </omt-form-element>

        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'TICKET.PROJECT' | translate">
          @if (!state.editMode) {
            <div>
              {{ ticket.project?.name | empty }}
            </div>
          } @else {
            <omt-project-dropdown
              [disabled]="!ticket.customer"
              [(selectedProject)]="ticket.project"
              [selectedCustomerId]="ticket.customer?.id"
              [clearable]="true"></omt-project-dropdown>
          }
        </omt-form-element>
      </div>


      <div fxLayout="row" fxLayoutGap="2rem" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="start">
        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'TICKET.PRIORITY' | translate" required [showRequiredSymbol]="state.editMode">
          @if (!state.editMode) {
            <span>
              <i
                class="fas fa-circle"
                [ngClass]="{
                          'priority-low': ticket.priority.id === 1 || ticket.priority.id === 7,
                          'priority-medium': ticket.priority.id === 2,
                          'priority-high': ticket.priority.id === 3,
                          'priority-critical': ticket.priority.id === 4,
                          'priority-info': ticket.priority.id > 4
                        }"></i>
              {{ ticket.priority.name }}
            </span>
          } @else {
            <omt-priority-dropdown [(priority)]="ticket.priority" (priorityChange)="changePriority($event)"></omt-priority-dropdown>
          }
        </omt-form-element>

        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'TICKET.CONTINUOUS' | translate">
          @if (!state.editMode) {
            <div>{{ ticket.continuous ? ('GLOBAL.YES' | translate) : ('GLOBAL.NO' | translate) }}
            </div>
          } @else {
            <ng-select
              [(ngModel)]="ticket.continuous"
              bindLabel="name"
              bindValue="value"
              [items]="boolOptions"
              [placeholder]="'TICKET.PH_CONTINUOUS' | translate"
              [clearable]="false"
              class="redesign">
            </ng-select>
          }
        </omt-form-element>
      </div>

      <div fxLayout="row" fxLayoutGap="2rem" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="start">
        @if (state.canLinkBoards) {
          <omt-form-element fxFlex="50" fxFlex.xl="50" label="Boards">
            @if (!state.editMode) {
              <div>
                <span> {{ linkedBoardNames() | empty }}</span>
              </div>
            } @else {
              @if (state.editMode) {
                <ng-select
                  [(ngModel)]="boardsToLink"
                  (ngModelChange)="boardsToLinkChange.emit($event)"
                  bindValue="id"
                  bindLabel="name"
                  [items]="$boards | async"
                  [placeholder]="'TICKET.PH_BOARDS' | translate"
                  class="redesign"
                  [multiple]="true">
                  <ng-template ng-option-tmp let-item="item">
                    <option>{{ item.name }}</option>
                  </ng-template>
                </ng-select>
              }
            }
          </omt-form-element>
        }
        <omt-form-element fxFlex="50" fxFlex.xl="50" [label]="'TICKET.TIME_ESTIMATED' | translate">
          @if (!state.editMode) {
            <div>
              <span matTooltip="{{ ticket.estimatedTime | timeFormatFromMs }} {{ 'TIME.HRS' | translate }}"
                    [matTooltipDisabled]="!ticket.estimatedTime">{{ timeTrackingData.estimatedTimeText | empty }}
              </span>
            </div>
          } @else {
            <omt-time-span-input withDays [initializeWithCurrentTime]="false" [(timeSpan)]="ticket.estimatedTime"
                                 (timeSpanChange)="estimatedTimeChange.emit()"></omt-time-span-input>
          }
        </omt-form-element>
      </div>


      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="start">
        <omt-form-element fxFlex="50" fxFlex.xl="50" [hidden]="!state.canSeeDeductionDuration" [label]="'TICKET.DEDUCTION_DURATION' | translate">
          @if (!state.editMode) {
            <span> {{ timeTrackingData.deductionTimeText | empty }}</span>
          } @else {
            @if (state.editMode) {
              <input
                type="time"
                class="redesign"
                [ngModel]="ticket.deductionDuration | timeFormatFromMs"
                (ngModelChange)="setTime($event, 'deductionDuration')"
              />
            }
          }

        </omt-form-element>
      </div>
    </div>
    <!--Description-->
    <div class="section-header mb-0">
      <i class="fas fa-pen"></i>
      {{ 'TICKET.DESCRIPTION' | translate }}
    </div>

    <div [ngClass]="{'p-1': state.editMode}" class="mb-0">
      <omt-editor [editMode]="state.editMode" [(value)]="ticket.description" [maxLength]="maxDescriptionChars" class="rounded"></omt-editor>
      @if (state.editMode) {
        <omt-max-character-hint [maxChars]="maxDescriptionChars" [value]="ticket.description"></omt-max-character-hint>
      }
    </div>
    @if (ticket.external && (state.editMode || ticket.externalDescription)) {
      <div class="section-header mb-0" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <i class="fas fa-pen"></i>
          {{ 'TICKET.EXTERNAL_DESCRIPTION' | translate }}
          <omt-help-icon class="ml-1" [helpText]="'TICKET.EXTERNAL_DESCRIPTION_INFOTEXT' | translate"/>
        </div>
      </div>
      <div [ngClass]="{'p-1': state.editMode}">
        @if (state.editMode) {
          <div class="mb-1" fxLayout="row" fxLayoutAlign="end">
            <omt-button type="flat" color="primary" (click)="copyDescription()">
              <i class="fas fa-copy mr-1"></i>
              <span>{{ 'TICKET.COPY_DESCRIPTION' | translate }}</span>
            </omt-button>
          </div>
        }
        <omt-editor [editMode]="state.editMode" [(value)]="ticket.externalDescription" [maxLength]="maxDescriptionChars" class="rounded"/>
        @if (state.editMode) {
          <omt-max-character-hint [maxChars]="maxDescriptionChars" [value]="ticket.externalDescription"/>
        }
      </div>
    }
  </mat-card>
</div>
