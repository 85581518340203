import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

import {TranslateService} from '@ngx-translate/core';
import {LayoutConst} from '@core/constants/layout';
import {TicketTypeModel} from '@ticket/models';
import {TicketTypeService} from '@management/tickets/ticket-types/ticket-type.service';
import {SnackbarService} from '@core/services/snackbar.service';

@Component({
    selector: 'omt-ticket-type-dialog',
    templateUrl: './ticket-type-dialog.component.html'
})
export class TicketTypeDialogComponent implements OnInit {
    @Output()
    newTicketType = new EventEmitter<TicketTypeModel>();

    @Output()
    updatedTicketType = new EventEmitter<TicketTypeModel>();

    layout = LayoutConst;

    constructor(
        public dialogRef: MatDialogRef<TicketTypeDialogComponent>,
        private readonly ticketTypeService: TicketTypeService,
        private readonly snackbarService: SnackbarService,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public ticketType: TicketTypeModel
    ) {
    }

    ngOnInit(): void {
        if (!this.ticketType) {
            this.ticketType = new TicketTypeModel();
        }

    }

    /*--------------------------------------------------------------
    # Actions
    --------------------------------------------------------------*/

    save(): void {
        if (!this.ticketType.name) {
            this.snackbarService.open(this.translateService.instant('MANAGEMENT.NAME_IS_MISSING'));
            return;
        }

        if (!this.ticketType.id) {
            this.ticketTypeService.createTicketType(this.ticketType).subscribe((data: TicketTypeModel) => {
                this.newTicketType.emit(data);
            });
        } else {
            this.ticketTypeService.updateTicketType(this.ticketType).subscribe((data: TicketTypeModel) => {
                this.updatedTicketType.emit(data);
            });
        }

        this.closeDialog();
    }

    /*--------------------------------------------------------------
   # Modal
   --------------------------------------------------------------*/

    closeDialog(): void {
        this.dialogRef.close();
    }
}
