import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketDetailState} from '@ticket/ticket-detail-v2/ticket-detail-state';
import {TicketModel} from '@ticket/models';
import {TicketService} from '@ticket/ticket.service';
import {BusinessUnitModel} from '@core/models/businessunit/business-unit.model';

@Component({
    selector: 'omt-ticket-assignees',
    templateUrl: './ticket-assignees.component.html',
    styleUrls: ['./ticket-assignees.component.scss']
})
export class TicketAssigneesComponent implements OnInit {
    @Input() state: TicketDetailState;
    @Input() ticket: TicketModel;
    @Output() takeResponsibility = new EventEmitter<void>();
    @Output() assigneeChange = new EventEmitter<BusinessUnitModel>();

    observerString: string;

    constructor(private readonly ticketService: TicketService) {
    }

    ngOnInit(): void {
        this.readObserversIntoString();
    }

    changeAssignee(event: BusinessUnitModel): void {
        this.assigneeChange.emit(event);
    }

    readObserversIntoString(): void {
        this.observerString = this.ticketService.buildObserverText(this.ticket.ticketObservers);
    }
}
