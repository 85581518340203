export class TicketTimeTrackingData {
    constructor() {
    }

    bookingTime: number;
    timeTrackingProgress: number;
    budgetProgress: number;
    usedBudgetText: string;
    estimatedTimeText = '–';
    maximumTimeText = '–';
    deductionTimeText = '–';
    remainingTimeText = '–';
    bookingTimeText = '–';
}
