import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomerReferenceModel, CustomerReferencePersonModel} from '@customer/models';
import {DialogService} from '@core/services/global/dialog.service';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {CustomerService} from '@customer/customer.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CoreModule} from '@core/core.module';
import {AvatarModule} from '@libs/ui/src';
import {FlexModule} from '@ngbracket/ngx-layout';

@Component({
  selector: 'omt-customer-ref-person-dropdown',
  standalone: true,
    imports: [
        NgSelectModule,
        FormsModule,
        TranslateModule,
        CoreModule,
        AvatarModule,
        FlexModule
    ],
  templateUrl: './customer-ref-person-dropdown.component.html',
  styleUrl: './customer-ref-person-dropdown.component.scss'
})
export class CustomerRefPersonDropdownComponent extends EntityDropdown {
    @Input()
    set customerId(customerId: number) {
        if (!customerId) {
            this.disabled = true;
            return;
        }

        this._customerId = customerId;
        this.loadRefPersons();
        this.disabled = false;
    }

    get customerId(): number {
        return this._customerId;
    }

    private _customerId: number;

    @Input() value: CustomerReferencePersonModel;
    @Output() valueChange = new EventEmitter<CustomerReferencePersonModel>();

    customerRefPersons: CustomerReferencePersonModel[] = [];

    constructor(private readonly customerService: CustomerService,
                private readonly dialogService: DialogService) {
        super();
    }

    onValueChange(event: CustomerReferencePersonModel): void {
        this.valueChange.emit(event);
    }

    loadRefPersons(): void {
        this.isLoading = true;

        this.subs.sink = this.customerService.getCustomerReferencePersons(this._customerId).subscribe((x) => {
            this.customerRefPersons = x;
            this.isLoading = false;
        });
    }

    openDialog(): void {
        const dialogRef = this.dialogService.openCustomerReferencePersonDialog({ customerId: this.customerId, refPerson: null });

        this.subs.sink = dialogRef.componentInstance.newPerson.subscribe((newRefPerson) => {
            this.onValueChange(newRefPerson);
            this.value = newRefPerson;
            this.customerRefPersons = [...this.customerRefPersons, newRefPerson];
        });
    }
}
