import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TicketListComponent} from '@ticket/ticket-list/ticket-list.component';
import {TicketDetailV2Component} from '@ticket/ticket-detail-v2/ticket-detail-v2.component';
import {PermissionGuard, PermissionGuardClause} from '@core/guards/permission.guard';

const TicketRoutes: Routes = [
    {
        path: '',
        component: TicketListComponent,
        loadChildren: () => import('./ticket-list/ticket-list.module').then((x) => x.TicketListModule),
        canActivate: [PermissionGuard],
        data: {
            permission: new PermissionGuardClause('ticket')
        }
    },
    {
        path: 'create',
        component: TicketDetailV2Component,
        loadChildren: () => import('./ticket-detail-v2/ticket-detail-v2.module').then((x) => x.TicketDetailV2Module),
        canActivate: [PermissionGuard],
        data: {
            permission: [
                new PermissionGuardClause('ticket'),
                new PermissionGuardClause('ticketsErstellen')
            ]
        }
    },
    {
        path: 'template/:id',
        component: TicketDetailV2Component,
        loadChildren: () => import('./ticket-detail-v2/ticket-detail-v2.module').then((x) => x.TicketDetailV2Module),
        canActivate: [PermissionGuard],
        data: {
            templateMode: true,
            permission: new PermissionGuardClause('ticket')
        }
    },
    {
        path: 'template',
        component: TicketDetailV2Component,
        loadChildren: () => import('./ticket-detail-v2/ticket-detail-v2.module').then((x) => x.TicketDetailV2Module),
        canActivate: [PermissionGuard],
        data: {
            templateMode: true,
            permission: new PermissionGuardClause('ticket')
        }
    },
    {
        path: ':id',
        component: TicketDetailV2Component,
        loadChildren: () => import('./ticket-detail-v2/ticket-detail-v2.module').then((x) => x.TicketDetailV2Module),
        canActivate: [PermissionGuard],
        data: {
            permission: new PermissionGuardClause('ticket')
        }
    }
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(TicketRoutes)],
    exports: [RouterModule]
})
export class TicketV2RoutingModule {
}
