import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatLegacyProgressBarModule} from '@angular/material/legacy-progress-bar';
import {DecimalPipe} from '@angular/common';
import {FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyTooltipModule} from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {TicketToDoModel} from '@ticket/models';
import {TodoProgress, ToDoProgressService} from '@ticket/ticket-todo/to-do-progress-bar/to-do-progress.service';
import {coerceBoolean} from '@core/decorators/coerce-boolean';

@Component({
    selector: 'omt-to-do-progress-bar',
    templateUrl: './to-do-progress-bar.component.html',
    styleUrls: ['./to-do-progress-bar.component.scss'],
    imports: [
        MatLegacyProgressBarModule,
        DecimalPipe,
        FlexModule,
        MatLegacyTooltipModule,
        TranslateModule
    ],
    standalone: true
})
export class ToDoProgressBarComponent implements OnChanges {
    @Input() todos: TicketToDoModel[] = [];

    @coerceBoolean()
    @Input() showText = true;

    @coerceBoolean()
    @Input() showProgressBar = true;

    todoProgress: TodoProgress;

    constructor(private readonly todoProgressService: ToDoProgressService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.todos) {
            this.todoProgress = this.todoProgressService.calculateProgress(this.todos);
        }
    }
}
