import {Injectable} from '@angular/core';
import {TicketModel, TicketWorklogModel} from '@ticket/models';
import moment from 'moment';
import {TicketWorkLogService} from '@ticket/ticket-work-log.service';
import {TicketTimeTrackingData} from '@ticket/ticket-detail-v2/ticket-time-tracking-data';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class TicketTimeTrackingDataService {
    constructor(private readonly workLogService: TicketWorkLogService,
                private readonly translate: TranslateService) {
    }

    processTimeTrackingData(ticket: TicketModel, workLogs: TicketWorklogModel[]): TicketTimeTrackingData {
        const data = new TicketTimeTrackingData();
        data.bookingTime = this.workLogService.calculateWorkLogDuration(workLogs);
        data.estimatedTimeText = this.getDurationText(ticket.estimatedTime);
        data.bookingTimeText = this.getDurationText(data.bookingTime);
        data.deductionTimeText = this.getDurationText(ticket.deductionDuration);
        data.maximumTimeText = this.getDurationText(ticket.maximumTime);
        data.remainingTimeText = ticket.estimatedTime > 0 && data.bookingTime < ticket.estimatedTime ? this.getDurationText(ticket.estimatedTime - data.bookingTime) : '0h';
        data.timeTrackingProgress = Math.round((data.bookingTime / ticket.maximumTime) * 100);

        if (Number.isNaN(data.timeTrackingProgress)) {
            data.timeTrackingProgress = 0;
        }

        return data;
    }

    private getDurationText(milliseconds: number): string {
        // load translations
        const daysIdentifier = this.translate.instant('GLOBAL.DAYS');
        const hourIdentifier = this.translate.instant('GLOBAL.HOURS');
        const minuteIdentifier = this.translate.instant('GLOBAL.MINUTES');

        if (milliseconds <= 0) {
            return this.translate.instant('GLOBAL.NONE') ?? 'Keine';
        }

        const duration = moment.duration(milliseconds, 'milliseconds');
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const daysText = (days === 1 ? daysIdentifier.substring(0, daysIdentifier.length - 1) : daysIdentifier) + ' ';
        const hoursText = (hours === 1 ? hourIdentifier.substring(0, hourIdentifier.length - 1) : hourIdentifier) + ' ';
        const minutesText = minutes === 1 ? minuteIdentifier.substring(0, minuteIdentifier.length - 1) : minuteIdentifier;
        return (days > 0 ? days + ' ' + daysText : '')
            + (hours > 0 ? hours + ' ' + hoursText : '')
            + (minutes > 0 ? minutes + ' ' + minutesText : '');
    }
}
