<mat-card class="detail-card p-0 rounded">
  <div class="section-header" fxLayout="row" fxLayoutAlign="start center">
    <i class="fas fa-clock"></i>
    {{ 'TICKET.WORK_LOGS' | translate }}
  </div>
  <omt-worklog-table
    [ticketId]="ticket.id"
    [workLogs]="ticket.workLogs"
    (workLogChanged)="updateWorkLogs($event)"
    (worklogsLoaded)="updateWorkLogs($event)">
  </omt-worklog-table>
</mat-card>
