<ng-select
  [(ngModel)]="selectedTemplate"
  bindLabel="name"
  [items]="templates | sortBy: 'asc':'name'"
  (change)="onChangeTemplate()"
  [placeholder]="'TICKET.PH_TEMPLATE' | translate"
  [loading]="isLoading"
  appendTo="body"
  #ngSelect
  class="entity-dropdown"
>
  <ng-template ng-footer-tmp>
    <mat-card class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
      <button mat-mini-fab matTooltip="Neue Vorlage erstellen" class="rounded" (click)="openDialog(); ngSelect.close()">
        <i class="fas fa-plus"></i>
      </button>
    </mat-card>
  </ng-template>
</ng-select>
