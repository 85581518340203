import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SortByPipe} from '@core/pipes/sort-by.pipe';
import {TicketTemplateCreateRequest, TicketTemplateModel, TicketTemplateUpdateRequest} from '@management/tickets/template/ticket.template.model';
import {TicketToDoModel} from '@ticket/models';
import {ApiService} from '@core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class TicketTemplateHttpService {
    private templateControllerUrl = 'api/TicketTemplate/';
    private sortByPipe = new SortByPipe();

    constructor(private readonly apiService: ApiService) {
    }

    createTicketTemplate(issueTemplate: TicketTemplateCreateRequest): Observable<TicketTemplateModel> {
        return this.apiService.post<TicketTemplateModel>(this.templateControllerUrl + 'CreateTicketTemplate', issueTemplate);
    }

    updateTicketTemplate(issueTemplate: TicketTemplateUpdateRequest): Observable<TicketTemplateModel> {
        return this.apiService.post<TicketTemplateModel>(this.templateControllerUrl + 'UpdateTicketTemplate', issueTemplate);
    }

    getTicketTemplates(): Observable<TicketTemplateModel[]> {
        return this.apiService.get<TicketTemplateModel[]>(this.templateControllerUrl + 'TicketTemplates').pipe(map((x) => this.sortByPipe.transform(x, 'asc', 'name')));
    }

    getTicketTemplateById(templateId: number): Observable<TicketTemplateModel> {
        const params = new HttpParams().set('templateId', templateId.toString());

        return this.apiService.get<TicketTemplateModel>(this.templateControllerUrl + 'GetTemplateById', params);
    }

    getTemplatesByProjectTemplateId(templateId: number): Observable<TicketTemplateModel[]> {
        if (!templateId || isNaN(templateId)) {
            throw new Error('Invalid project template ID!');
        }

        return this.apiService.get<TicketTemplateModel[]>('api/TicketTemplate/GetTemplatesByProjectTemplateId',
            new HttpParams().set('projectTemplateId', templateId.toString())).pipe(map((x) => this.sortByPipe.transform(x, 'asc', 'name')));
    }

    getToDos(templateId: number): Observable<TicketToDoModel[]> {
        return this.apiService.get<TicketToDoModel[]>(this.templateControllerUrl + 'TemplateToDos?templateId=' + templateId.toString());
    }

    deleteTicketTemplate(id: number): Observable<string> {
        return this.apiService.delete<string>(this.templateControllerUrl + 'deleteTicketTemplate?id=' + id.toString());
    }
}
